


// --------------------------------------------------------------------------

import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { WishlistContext } from "../../context/WishlistContext";
import { useCart } from "../../context/CartContext";

const IconGroup = ({ iconWhiteClass }) => {
  const navigate = useNavigate();
  const { wishlistCount,fetchWishlistData } = useContext(WishlistContext);
  const { cartCount,fetchCartData } = useCart();

  useEffect(() => {
    // Check if user is logged in
    const token = localStorage.getItem('token');
    if (token) {
      // Fetch latest counts when component mounts or token changes
      fetchWishlistData();
      fetchCartData();
    }
  }, [localStorage.getItem('token')]);

  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };
  const handleLogout = () => {
   
    localStorage.removeItem('token');
    window.location.reload();

    fetchWishlistData();
    fetchCartData();
    navigate(process.env.PUBLIC_URL + "/login-register"); // Corrected the route
  };
  
  const token = localStorage.getItem('token'); 


  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)}>
      <div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={e => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div>
      <div className="same-style account-setting ">
        <button
          className="account-setting-active"
          onClick={e => handleClick(e)}
        >
          <i className="pe-7s-user-female" />
        </button>
        <div className="account-dropdown">
          <ul>
            {/* <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>Login</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                Register
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/my-account"}>
                my account
              </Link>
            </li>
            <li>
            <Link to="#" onClick={handleLogout}>
                Logout
              </Link>
            </li> */}
            {!token ? (
              <>
                {/* Show login and register when no token */}
                <li>
                  <Link to={process.env.PUBLIC_URL + "/login-register"}>Login</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/login-register"}>Register</Link>
                </li>
              </>
            ) : (
              <>
                {/* Show my account and logout if token exists */}
                <li>
                  <Link to={process.env.PUBLIC_URL + "/my-account"}>My Account</Link>
                </li>
                <li>
                  <Link to="#" onClick={handleLogout}>Logout</Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistCount}
          </span>
        </Link>
      </div>
      <div className="same-style cart-wrap d-none d-lg-block">
        {/* <button className="icon-cart" to={process.env.PUBLIC_URL + "/cart"} >
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartCount}
          </span>
        </button> */}
         <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartCount}
          </span>
        </Link>
        {/* menu cart */}
        {/* <MenuCart /> */}
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartCount}
          </span>
        </Link>
      </div>

      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
};

export default IconGroup;